import React from 'react';
import Layout from "../../layout/Layout";
import SubmitApplicationBlock from "../../blocks/static/SubmitApplicationBlock";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import StepsBlock from "../../blocks/StepsBlock";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";
import SEO from "../../components/seo";

import enMessages from '../../i18n/marketplaces/en.js'
import ruMessages from '../../i18n/marketplaces/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

export const MarketplacesPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />
            <StepsBlock
                title={messages[locale].stepsBlock.title}
                list={messages[locale].stepsBlock.list}
                listColor="first"
                listType="ul"
                config={{
                    underPrev: true
                }}
            />
            <InfoCardsBlock
                cardsTitle={messages[locale].infoCardsBlock.title}
                cardsLayout="row"
                cardsTitleColor="first"
                cards={messages[locale].infoCardsBlock.cards}
                listTitle={messages[locale].infoCardsBlock.listTitle}
                listPosition="afterCards"
                listColor="first"
                list={messages[locale].infoCardsBlock.list}
                listGoTo={messages[locale].infoCardsBlock.listGoTo}
            />
            <SubmitApplicationBlock
                title={messages[locale].submitApplicationBlock.title}
                textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />
        </Layout>
    )
};

export default MarketplacesPage;
