import imageScheme from "../../images/schemes/marketplaces_scheme.svg";
import links from "../../utils/links";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconWorld from "../../images/icons/styled/second/World.svg";
import iconCrossWithPointer from "../../images/icons/styled/first/CrossWithPointer.svg";
import iconApi from "../../images/icons/styled/first/API.svg";

export default {
    "seo": {
        "title": "Интернет-эквайринг для маркетплейсов  | Банк 131",
        "description": "Сплит-платежи — интернет-эквайринг для маркетплейсов и агрегаторов с возможностью разделить платежи между несколькими поставщиками, партнерами"
    },
    "pageHeaderBlock": {
        "title": "Мультирасчеты для&nbsp;маркетплейсов",
        "text": "Маркетплейсам и&nbsp;агрегаторам для расчетов с&nbsp;поставщиками и&nbsp;партнерами."
    },
    "stepsBlock": {
        "title": "Как это работает",
        "list":[
            {
                "title": 'Платеж',
                "text": 'Покупатель оплачивает услугу на сайте или в приложении маркетплейса'
            },
            {
                "title": 'Распределение платежа',
                "text": 'Платеж распределяется между всеми поставщиками: маркетплейс получает комиссию, продавцы и другие партнёры, например, служба доставки — оплату товаров или услуг.'
            },
            {
                "title": 'Прозрачные процессы',
                "text": 'Весь процесс движения денег прозрачен для маркетплейса.',
                "image": imageScheme,
                "imageLabel": 'Посмотреть схему'
            }
        ]
    },
    "infoCardsBlock": {
        "title": "Вам понравится",
        "listTitle": "С чего начать",
        "list": [
            'Отправьте нам заявку',
            'Мы подберём решение для вашего бизнеса ',
            'Проведём идентификацию, проверим документы ',
            'Подпишем договор ',
            `Готово, можно начинать интеграцию по <a href="${links.ru.api}" target="_blank" rel="noreferrer">нашему API</a>`
        ],
        "listGoTo": {
            "to": '/pricing',
            "text": 'Тарифы'
        },
        "cards": [
            {
                text: 'Приём платежей банковскими картами Visa, MasterCard и МИР',
                icon: iconCard,
                theme: 'cardFirst'
            },
            {
                text: 'Выплаты нескольким получателям — юридическим лицам',
                icon: iconCrossWithPointer,
                theme: 'cardFirst'
            },
            {
                text: 'Юридические лица могут быть резидентами РФ или международными компаниями',
                icon: iconWorld,
                theme: 'cardFirst'
            },
            {
                text: 'Управление выплатами по API',
                icon: iconApi,
                theme: 'cardFirst'
            }
        ]
    },
    "submitApplicationBlock": {
        "title": "Оставить заявку",
        "textSubmit": "Узнать больше"
    }
}