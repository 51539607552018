import imageScheme from "../../images/schemes/Scheme-For-Marketplaces-EN.svg";
import links from "../../utils/links";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconWorld from "../../images/icons/styled/second/World.svg";
import iconCrossWithPointer from "../../images/icons/styled/first/CrossWithPointer.svg";
import iconApi from "../../images/icons/styled/first/API.svg";

export default {
    "seo": {
        "title": "Payment service for marketplaces | Bank 131",
        "description": "Split payments: internet acquiring for marketplaces and aggregators, splitting payments between vendors and partners"
    },
    "pageHeaderBlock": {
        "title": "Split payment for marketplaces",
        "text": "Split accepted online payments between several recipients: sellers and partners."
    },
    "stepsBlock": {
        "title": "How it works",
        "list":[
            {
                "title": 'Payment ',
                "text": 'A customer makes a payment on a website or in an app'
            },
            {
                "title": 'Split',
                "text": 'The payment is distributed among all the providers: the marketplace receives a ' +
                    'commission, and sellers and other partners, such as delivery services, receive payment. '
            },
            {
                "title": 'Transparency',
                "text": 'The marketplace can see the money change hands.',
                "image": imageScheme,
                "imageLabel": 'View diagram'
            }
        ]
    },
    "infoCardsBlock": {
        "title": "You’ll like it",
        "listTitle": "Getting started",
        "list": [
            'Send a request, sharing your requirements and goals',
            'We’ll find you a suitable solution ',
            'We’ll also need to check your documents ',
            'Next, you sign a contract ',
            `And you’re all set to start <a href="${links.ru.api}" target="_blank" rel="noreferrer">API integration </a>`
        ],
        "listGoTo": {
            "to": '/en/pricing',
            "text": 'Pricing'
        },
        "cards": [
            {
                "text": 'Accept online payments with Visa, MasterCard and MIR',
                "icon": iconCard,
                "theme": 'cardFirst'
            },
            {
                "text": 'Payouts to multiple companies',
                "icon": iconCrossWithPointer,
                "theme": 'cardFirst'
            },
            {
                "text": 'Available for Russian and international companies',
                "icon": iconWorld,
                "theme": 'cardFirst'
            },
            {
                "text": 'Use an API to route payments',
                "icon": iconApi,
                "theme": 'cardFirst'
            }
        ]
    },
    "submitApplicationBlock": {
        "title": "How to connect payments",
        "textSubmit": "Sign up",
        "textSubmitBottom": "Get in touch"
    }
}